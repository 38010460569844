"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFetchComments = void 0;
const api_1 = require("@helper-hooks/api");
const types_provider_1 = require("@providers/types-provider");
const useGetQuery_1 = require("./useGetQuery");
const useFetchComments = (useQuery, threadId) => {
    return (0, useGetQuery_1.useGetQuery)(useQuery, [types_provider_1.SharedQueryKeys.COMMENTS, threadId], () => api_1.api.collaboration.getArtifactComments(threadId), {}, {
        enabled: false
    });
};
exports.useFetchComments = useFetchComments;
