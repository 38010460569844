"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.putUserPreferencesFailure = exports.putUserPreferenceSuccess = exports.putUserPreferenceRequest = exports.getUserPreferencesFailure = exports.getUserPreferencesSuccess = exports.getUserPreferencesRequest = void 0;
const userPreferencesTypes_1 = require("./userPreferencesTypes");
const getUserPreferencesRequest = (contextId, keys) => ({
    type: userPreferencesTypes_1.UserPreferenceAction.GET_USER_PREFERENCE_REQUEST,
    payload: {
        contextId,
        keys
    }
});
exports.getUserPreferencesRequest = getUserPreferencesRequest;
const getUserPreferencesSuccess = (preferences) => ({
    type: userPreferencesTypes_1.UserPreferenceAction.GET_USER_PREFERENCE_SUCCESS,
    payload: {
        preferences
    }
});
exports.getUserPreferencesSuccess = getUserPreferencesSuccess;
const getUserPreferencesFailure = (error) => ({
    type: userPreferencesTypes_1.UserPreferenceAction.GET_USER_PREFERENCE_FAILURE,
    error
});
exports.getUserPreferencesFailure = getUserPreferencesFailure;
const putUserPreferenceRequest = (contextId, key, preferences, currentPreferences) => ({
    type: userPreferencesTypes_1.UserPreferenceAction.PUT_USER_PREFERENCES_REQUEST,
    payload: {
        contextId,
        key,
        preferences,
        currentPreferences
    }
});
exports.putUserPreferenceRequest = putUserPreferenceRequest;
const putUserPreferenceSuccess = (preferences) => ({
    type: userPreferencesTypes_1.UserPreferenceAction.PUT_USER_PREFERENCES_SUCCESS,
    payload: preferences
});
exports.putUserPreferenceSuccess = putUserPreferenceSuccess;
const putUserPreferencesFailure = (error) => ({
    type: userPreferencesTypes_1.UserPreferenceAction.PUT_USER_PREFERENCES_FAILURE,
    error
});
exports.putUserPreferencesFailure = putUserPreferencesFailure;
