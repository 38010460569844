"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserPreferenceAction = exports.SettingsType = exports.WorkflowType = void 0;
var WorkflowType;
(function (WorkflowType) {
    WorkflowType["WORKLIST"] = "worklist";
    WorkflowType["MUSIC"] = "mcw";
    WorkflowType["VIDEO"] = "vcw";
    WorkflowType["APPS"] = "acw";
    WorkflowType["COPY"] = "cw";
    WorkflowType["TASK"] = "task";
})(WorkflowType || (exports.WorkflowType = WorkflowType = {}));
var SettingsType;
(function (SettingsType) {
    SettingsType["BETA_FEATURES"] = "betaFeatures";
    SettingsType["SETTINGS"] = "settings";
})(SettingsType || (exports.SettingsType = SettingsType = {}));
var UserPreferenceAction;
(function (UserPreferenceAction) {
    UserPreferenceAction["GET_USER_PREFERENCE_REQUEST"] = "GET_USER_PREFERENCE_REQUEST";
    UserPreferenceAction["GET_USER_PREFERENCE_SUCCESS"] = "GET_USER_PREFERENCE_SUCCESS";
    UserPreferenceAction["GET_USER_PREFERENCE_FAILURE"] = "GET_USER_PREFERENCE_FAILURE";
    UserPreferenceAction["PUT_USER_PREFERENCES_REQUEST"] = "PUT_USER_PREFERENCES_REQUEST";
    UserPreferenceAction["PUT_USER_PREFERENCES_SUCCESS"] = "PUT_USER_PREFERENCES_SUCCESS";
    UserPreferenceAction["PUT_USER_PREFERENCES_FAILURE"] = "PUT_USER_PREFERENCES_FAILURE";
})(UserPreferenceAction || (exports.UserPreferenceAction = UserPreferenceAction = {}));
exports.default = UserPreferenceAction;
