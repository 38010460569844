import React, { FC, MouseEvent, useEffect, useMemo, useState } from 'react';
import { Box } from '@tidbits/react-tidbits';
import { withModal } from '@providers/modal-provider';
import InfoModal from '@ui-components/info-modal';
import ReassignTableList from '@ui-components/reassign-table-list';
import UnassignTask from '@ui-components/unassign-task';
import { Column } from '@ui-components/table';
import { useDispatch, useSelector } from 'react-redux';

import { worklistSelectedTasks } from '../../store/worklist/worklistSelector';
import { updateBulkTaskRequest } from '../../store/task/taskActions';
import { reassignConfigV2 } from './ReassignConfig';
import { vendorProfile } from '../../api/worklist';
import { getV1NamespaceFromV2WorkflowIdentifier } from '@helper-hooks/general-utils';
import { WorkflowIdentifiersType } from '@providers/types-provider/src/WorkflowIdentifiersType';
import { QualifiedUser, ReassignUserPayload } from '@providers/types-provider';
// TODO: Do not remove will use for bulk due dates updt.
// import { QualifiedUser, ReassignUserPayload, Schedule } from '@providers/types-provider';
// import BulkDueDates from './BulkDuedates';
import Tabs from '@ui-components/tabs';
import { mappedTaskState, emptyUser } from './utils';
import { api } from '@helper-hooks/api';

interface Props {
  onClose: () => void;
}

type ReassignTab = {
  key: string;
  label: string;
};

export const ReassignTaskModal: FC<Props> = ({ onClose }) => {
  const dispatch = useDispatch();
  const selectedTasks = useSelector(worklistSelectedTasks);
  const qualifiedUsersRole = mappedTaskState(selectedTasks[0].state || '');
  const namespace = selectedTasks[0].namespace;
  const v1Namespace = getV1NamespaceFromV2WorkflowIdentifier(namespace as WorkflowIdentifiersType);
  const locales = [selectedTasks[0].locale];
  const tabs: ReassignTab[] = useMemo(
    () =>
      qualifiedUsersRole === 'writer'
        ? [
            { key: 'writer', label: 'Writer' },
            { key: 'editor', label: 'Editor' }
          ]
        : [{ key: qualifiedUsersRole, label: qualifiedUsersRole }],
    [qualifiedUsersRole]
  );
  const [reassignedUsers, setReassignedUsers] = useState<Record<string, QualifiedUser | null>>();
  // TODO: Do not remove will use for bulk due dates updt.
  // const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [activeTab, setActiveTab] = useState<string>(() => tabs[0].key);
  const [vendor, setVendor] = useState<string>();
  const [qualifiedUsers, setQualifiedUsers] = useState<Record<string, QualifiedUser[]>>({});
  const [isLoading, setIsLoading] = useState(false);
  const isReassignSubmitDisabled =
    reassignedUsers === undefined ||
    Object.values(reassignedUsers).every((value) => value === null);

  const isUserOnActiveTabUnassigned =
    reassignedUsers?.[activeTab || qualifiedUsersRole]?.email === '';

  const onReassignChange = (newUser: QualifiedUser) => {
    setReassignedUsers((old) => ({ ...old, [activeTab || qualifiedUsersRole]: newUser }));
  };

  const onUnassignChange = (newState: boolean) => {
    newState
      ? setReassignedUsers((old) => ({ ...old, [activeTab || qualifiedUsersRole]: emptyUser }))
      : setReassignedUsers((old) => ({ ...old, [activeTab || qualifiedUsersRole]: null }));
  };

  const onTaskReassign = () => {
    dispatch(
      updateBulkTaskRequest({ updateItems: createBulkUpdatePayload(), updateAttribute: 'reassign' })
    );
  };

  const createBulkUpdatePayload = () => {
    const newAssignee = reassignedUsers?.[qualifiedUsersRole];
    const newEditor = reassignedUsers?.['editor'];

    return selectedTasks.map(({ taskGuuid, namespace, state }) => {
      return {
        namespace,
        taskId: taskGuuid,
        command: {
          ...(newAssignee ? { assignee: newAssignee?.email || null } : {}),
          ...(newAssignee ? { assigneeName: newAssignee?.firstName || '' } : {}),
          ...(newEditor ? { editorAssignee: newEditor?.email || '' } : {})
        }
      };
    });
  };

  const handleTabClick = (e: MouseEvent<HTMLDivElement>) => {
    const { name } = (e.target as HTMLDivElement).dataset;
    if (!name) return;
    setActiveTab(name);

    if (!qualifiedUsers[name]) {
      fetchUsersToReassign({
        namespace,
        vendor: vendor as string,
        skill: {
          name,
          locales
        }
      });
    }
  };

  // TODO: This function is just temporary since sorting should be done on BE side - radar:75207376
  const onSortChange = (column: Column<QualifiedUser>, direction: string) => {
    if (direction === 'asc')
      // @ts-ignore
      return qualifiedUsers[activeTab].sort((a, b) =>
        // @ts-ignore
        a[column.dataIndex]?.toUpperCase() > b[column.dataIndex]?.toUpperCase() ? -1 : 1
      );
    if (direction === 'desc')
      // @ts-ignore
      return qualifiedUsers[activeTab].sort((a, b) =>
        // @ts-ignore
        a[column.dataIndex]?.toUpperCase() < b[column.dataIndex]?.toUpperCase() ? -1 : 1
      );
  };

  const fetchUsersToReassign = async (payload: ReassignUserPayload) => {
    const { name } = payload.skill;
    try {
      setIsLoading(true);
      const newQualifiedUsers = await api.qualifiedUsers(payload);
      setQualifiedUsers((old) => ({ ...old, [name]: newQualifiedUsers }));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  // TODO: Do not remove will use for due dates bulk updt.
  //   // We need this fetch for due dates
  //   // since worklist items do not contain data for it
  //   const fetchTasks = async () => {
  //     try {
  //       const responses = await Promise.all(
  //         selectedTasks?.map((task: any) => api.latestTask(task.taskURI))
  //       );
  //       setSchedules(responses.map((task) => task?.taskInfo.schedule));
  //     } catch (error) {
  //       console.error('Error fetching latest tasks', error);
  //       return [];
  //     }
  //   };
  //   fetchTasks();
  // }, []);

  useEffect(() => {
    vendorProfile(v1Namespace).then((profile: any) => {
      setVendor(profile.vendor);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Fetch initial qualified users (for one tab only)
    if (vendor === undefined) return;
    fetchUsersToReassign({
      namespace,
      vendor,
      skill: {
        name: tabs[0].key,
        locales
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendor]);

  return (
    <InfoModal
      isFullWidth
      // title="Manage Assignees & Schedule" DO not remove will use for due dates
      title="Manage Assignees"
      closeModal={onClose}
      successButtonDisabled={isReassignSubmitDisabled}
      onSuccess={() => {
        onTaskReassign();
        onClose();
      }}
      successLabel="Save"
    >
      <Box margin="0 auto" width="90%" position="relative" height="100%">
        <Tabs activeTabName={activeTab} handleTabClick={handleTabClick}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            position="sticky"
            top="74px"
            bg="white"
            zIndex="2"
            p={tabs.length > 1 ? '0' : '10px'}
          >
            <Tabs.TabsHeaderLayout>
              {/* Show tabs if more then 1 tab exists */}
              {tabs.length > 1 &&
                tabs?.map(({ label, key }) => (
                  <Tabs.TabHeader name={key} key={label}>
                    {label}
                  </Tabs.TabHeader>
                ))}
            </Tabs.TabsHeaderLayout>

            <UnassignTask
              onUnassignChange={onUnassignChange}
              isChecked={isUserOnActiveTabUnassigned}
              tooltip={{
                disabledMsg: 'Unassigning will make the task fallback to group.',
                enabledMsg: 'Toggle Unassigned to, see the list of eligible assignees'
              }}
            />
          </Box>
          <Tabs.TabsContentLayout>
            {tabs?.map(({ key }) => (
              <Tabs.TabPanel name={key} key={key}>
                {reassignedUsers?.[key]?.email !== '' && (
                  <ReassignTableList
                    loading={isLoading}
                    reassignListData={qualifiedUsers[key]}
                    reassignedUserId={reassignedUsers?.[key]?.identifier || ''}
                    onReassignChange={onReassignChange}
                    columns={reassignConfigV2}
                    onSortChange={onSortChange}
                  />
                )}
              </Tabs.TabPanel>
            ))}
          </Tabs.TabsContentLayout>
        </Tabs>
      </Box>
      {/* TODO: Do not remove will use for bulk due dates updt. */}
      {/* <Box py="20px">
        <HR sb="space10" />
        <Box margin="0 auto" width="90%">
          <BulkDueDates schedules={schedules} />
        </Box>
      </Box> */}
    </InfoModal>
  );
};

export default withModal(ReassignTaskModal);
