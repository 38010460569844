//@ts-nocheck
import React, { useState, useCallback, useEffect, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FontFaceObserver from 'fontfaceobserver';
import { ThemeProvider } from 'styled-components';
import { theme, Text } from '@tidbits/react-tidbits';
import { GlobalStyle } from '@tidbits/react-tidbits/global';
import InlineSpinner from '@ui-components/inline-spinner';
import { FETCH_USER } from './store/user/userActionTypes';
import createLoadingSelector from './store/loading/loadingSelector';
import createErrorMessageSelector from './store/error/errorSelector';
import { fetchCurrentUserRequest } from './store/user/userActions';
import { isUserAuthenticated } from './store/user/userSelector';
import CreateRoutes from './utils/RouteCreator';
import Layout from './components/layout/Layout';
import ServerErrorMessage from './components/messages/ServerErrorMessage';
import { reportError } from './utils/errorReportUtil';
import useInterval from '@helper-hooks/use-interval';
import './utils/moduleOverrides';
import { branchResolver } from './utils/envResolver';

import './App.scss';
import { useLocation } from 'react-router-dom';
import { getAlertsRequest } from './store/alerts/alertsAction';
import { selectGlobalAlerts } from './store/alerts/alertsSelector';
import { isEnvironment } from '@helper-hooks/general-utils';

const fontDefault = new FontFaceObserver('SF Pro');
const fontTableHeaders = new FontFaceObserver('SF Pro', { weight: 600 });
const fontCompact = new FontFaceObserver('SF Compact', { weight: 300 });

const loadingLoginSelector = createLoadingSelector([FETCH_USER]);
const errorLoginSelector = createErrorMessageSelector([FETCH_USER]);

const App: FC = () => {
  const [isInitiallyLoaded, setIsInitiallyLoaded] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const loading = useSelector(loadingLoginSelector);
  const isAuthenticated = useSelector(isUserAuthenticated);
  const alerts = useSelector(selectGlobalAlerts);
  const errorMessage = useSelector(errorLoginSelector);

  const fetchCurrentUser = useCallback(() => {
    dispatch(fetchCurrentUserRequest());
  }, [dispatch]);

  const fetchAlerts = useCallback(() => {
    dispatch(getAlertsRequest());
  }, [dispatch]);

  const isFetchingInProgress = loading || !isInitiallyLoaded;

  const setSiteTitle = () => {
    const branch = branchResolver();
    const isDevEnv = isEnvironment(['itms', 'local']);
    const hostname = window.location.hostname.split('.')[0];

    return isDevEnv
      ? `Wrkflow - ${hostname === 'wrkflow' ? 'local' : hostname} (${branch})`
      : 'Wrkflow';
    // return hostname === 'wrkflow' ? 'Wrkflow' : `Wrkflow - ${hostname} (${branch})`;
  };

  useEffect(() => {
    if (
      location.pathname !== '/welcome' &&
      location.pathname !== '/login' &&
      location.pathname !== '/worklist'
    ) {
      // Save the location that the user is browsing to,
      // if they are not authenticated they will be redirected here after
      localStorage.setItem('initialPath', location.pathname);
    }
  }, [location]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchAlerts();
    }
  }, [isAuthenticated, fetchAlerts]);

  useInterval(fetchAlerts, 60_000, isAuthenticated);

  useEffect(() => {
    // Temporary code to get chaplin to bypass /me calls
    //TODO remote once copy-management is moved separately.
    let location = window.location.href;
    let skipFetchUser =
      location === undefined ||
      location == null ||
      location.includes('chaplin') ||
      location.includes('tomasulo');

    if (!skipFetchUser) {
      fetchCurrentUser();
    }

    // large value for slow connection
    const timeout = 100000;

    Promise.all([
      fontDefault.load(null, timeout),
      fontTableHeaders.load(null, timeout),
      fontCompact.load(null, timeout)
    ])
      .then(() => {
        setIsInitiallyLoaded(true);
      })
      .catch((err) => {
        reportError(err);
        setIsInitiallyLoaded(true);
      });

    // Set site title
    document.title = setSiteTitle();
  }, [fetchCurrentUser]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <div className="mpe-app_wrapper">
        {isFetchingInProgress ? (
          <InlineSpinner visible={true} size={30} spinnerSpeed={500}>
            <Text textStyle="bodyRegular">Loading</Text>
          </InlineSpinner>
        ) : (
          <Layout
            isAuthenticated={isAuthenticated}
            locationPath={location.pathname}
            alerts={alerts}
          >
            <CreateRoutes location={location} isAuthenticated={isAuthenticated} />
            {errorMessage && (
              <ServerErrorMessage header={'Something went wrong'} message={errorMessage} />
            )}
          </Layout>
        )}
      </div>
    </ThemeProvider>
  );
};

export default App;
