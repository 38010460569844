"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetUserProfile = void 0;
const api_1 = require("@helper-hooks/api");
const types_provider_1 = require("@providers/types-provider");
const useGetQuery_1 = require("./useGetQuery");
const useGetUserProfile = (useQuery, namespace) => {
    return (0, useGetQuery_1.useGetQuery)(useQuery, types_provider_1.SharedQueryKeys.VENDOR, () => api_1.api.userProfile(namespace));
};
exports.useGetUserProfile = useGetUserProfile;
