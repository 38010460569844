"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFetchTaskHistory = void 0;
const api_1 = require("@helper-hooks/api");
const types_provider_1 = require("@providers/types-provider");
const useGetQuery_1 = require("./useGetQuery");
const useFetchTaskHistory = (useQuery, workflowTypePath, workflowId) => {
    return (0, useGetQuery_1.useGetQuery)(useQuery, types_provider_1.SharedQueryKeys.TASK_HISTORY, () => api_1.api.taskHistory.getTaskHistory(workflowTypePath, workflowId), workflowId);
};
exports.useFetchTaskHistory = useFetchTaskHistory;
