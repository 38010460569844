"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = userPreference;
const userPreferencesTypes_1 = require("./userPreferencesTypes");
const lodash_1 = require("lodash");
const initialState = {
    worklist: {
        sortDirection: '',
        sortField: '',
        filters: [],
        filtersV2: [],
        cursor: '',
        searchTerm: '',
        hiddenColumns: [],
        columnWidths: '',
        columnsOrderByIndex: []
    },
    betaFeatures: {
        worklist: {
            tableVersion: false
        },
        mcw: {},
        vcw: {},
        cw: {
            sseUserTracking: false
        }
    },
    settings: {
        worklist: {
            pageSize: 50
        },
        task: {
            sourceLocatedLeft: false
        }
    },
    filterPresets: {
        list: [],
        lastUsedPresetId: null
    }
};
function userPreference(state = initialState, action) {
    let stateCopy;
    switch (action.type) {
        case userPreferencesTypes_1.UserPreferenceAction.PUT_USER_PREFERENCES_SUCCESS:
            stateCopy = Object.assign({}, state);
            stateCopy = Object.assign(Object.assign({}, stateCopy), action.payload);
            return stateCopy;
        case userPreferencesTypes_1.UserPreferenceAction.GET_USER_PREFERENCE_SUCCESS:
            stateCopy = (0, lodash_1.cloneDeep)(state);
            return (0, lodash_1.merge)(stateCopy, action.payload.preferences);
        default:
            return state;
    }
}
