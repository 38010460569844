import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getNumberOfArchivedTasksStatus,
  getNumberOfSelectedTasksReadStatus,
  getNumberOfSelectedUnreadStatus,
  getNumberOfUnarchivedTasksStatus,
  getSelectedWorklistItemsByWorkflowType,
  getWorklistSelectedIds,
  worklistSelectedTasks,
  worklistTasksAssignable
} from '../../store/worklist/worklistSelector';
import { updateBulkTaskRequest } from '../../store/task/taskActions';
import { TaskListItem } from '../../common/types/TaskList';
import { SyntheticEvent } from 'react';
import { getUserPermissions } from '../../store/user/userSelector';
import { isAllowedByPermissions, userHasMultiplePermissions } from '../../utils/generalUtils';
import { showModal } from '../../store/modal/modalActions';
import { ModalSize } from '../../common/types';
import { MODAL_CONSTANTS } from '../../common/components/ModalContainer';
import { exportWorklistRequest, reportWorklistRequest } from '../../store/worklist/worklistActions';
import { isSingleWorkflowType } from '../../utils/worklistParams';
import { useModal } from '@providers/modal-provider';
import ReassignTaskModal from '../reassignTasksModal/ReassignTaskModal';
import createLoadingSelector from '../../store/loading/loadingSelector';
import { disableCommandFlagAction, userHasPermissionToMTAR } from './utils';
import { useLocation } from 'react-router-dom';
import ShareFiltersModal from '../modals/ShareFiltersModal/ShareFiltersModal';
import ImportFiltersModal from '../modals/ImportFiltersModal/ImportFiltersModal';
import { NewTableFilterConfig } from './NewTableFilterConfig';
import { DueDateExtensionModal } from '@ui-components/due-dates-extension-modal';
import { isEnvironment } from '@helper-hooks/general-utils';
import { ALL_ARCHIVE_PERMISSIONS } from './constants';

const loadingSelector = createLoadingSelector(['UPDATE_BULK_TASK']);

export const createBulkUpdatePayload = (
  selectedTaskIds: string[],
  namespace: string,
  updateAttribute: string,
  updateValue: boolean | string
) => {
  return selectedTaskIds.map((taskId: string) => {
    return {
      namespace,
      taskId,
      command: {
        [updateAttribute]: updateValue
      }
    };
  });
};

export const useActions = ({ activeFilters }: any) => {
  const devEnabledOnly = isEnvironment(['local', 'itms7', 'itms11']);

  const { pathname } = useLocation();
  const [lastClickedAction, setLastClickedAction] = useState('');
  const dispatch = useDispatch();
  const isBulkUpdatingTasks = useSelector(loadingSelector);
  const userPermissions = useSelector(getUserPermissions);
  const selectedTasks = useSelector(worklistSelectedTasks);
  const selectedTaskIds = useSelector(getWorklistSelectedIds);
  let [isReassignDisabled, setIsReassignDisabled] = useState(true);
  const { showModal: showRequestExtensionModal, hideModal: hideRequestExtensionModal } = useModal();

  // MTAR Specific
  const numberOfReadTasks = useSelector(getNumberOfSelectedTasksReadStatus);
  const numberOfUnreadTasks = useSelector(getNumberOfSelectedUnreadStatus);

  const isMarkedAsRead = numberOfReadTasks > numberOfUnreadTasks;
  const isMarkedAsReadDisabled = numberOfReadTasks > 0 && numberOfUnreadTasks > 0;
  const isRetryDisabled = selectedTasks.some(({ taskStatus }: any) => taskStatus !== 'ERRORED');

  // Archive Specific
  const numberOfArchivedTasks = useSelector(getNumberOfArchivedTasksStatus);
  const numberOfUnarchivedTasks = useSelector(getNumberOfUnarchivedTasksStatus);

  const isUnarchived = numberOfUnarchivedTasks > numberOfArchivedTasks;
  const bothArchivedUnarchivedTasksInSelection =
    numberOfArchivedTasks > 0 && numberOfUnarchivedTasks > 0;

  // Import Specific
  const selectedWorklistTasksByWorkflowTypes = useSelector(getSelectedWorklistItemsByWorkflowType);

  // Export Specific
  const isExportDisabled = (): boolean => {
    return (
      selectedTaskIds.length === 0 ||
      (selectedTaskIds.length > 0 && !isSingleWorkflowType(selectedWorklistTasksByWorkflowTypes))
    );
  };

  const exportWorklist = (data?: any) => {
    dispatch(exportWorklistRequest(data));
  };

  const exportReportSubmitAction = (data?: any) => {
    dispatch(reportWorklistRequest(data));
  };

  // Reassign Specific
  const worklistAssignable = useSelector(worklistTasksAssignable);
  const { showModal: showBulkReassignModal, hideModal: hideBulkReassignModal } = useModal();

  const showReassignModal = useCallback(() => {
    return showBulkReassignModal(<ReassignTaskModal onClose={hideBulkReassignModal} />);
  }, [showBulkReassignModal, hideBulkReassignModal]);

  // Share Filters Specifc
  const { showModal: showShareFiltersModal, hideModal: hideShareFiltersModal } = useModal();
  const { showModal: showImportFilterModal, hideModal: hideImportFilterModal } = useModal();

  const tasksAssignable = () => {
    return worklistAssignable;
  };

  useEffect(() => {
    if (worklistUniqueFields()) {
      setIsReassignDisabled(tasksAssignable());
    } else {
      setIsReassignDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTaskIds]);

  const worklistUniqueFields = () => {
    return (
      selectedTasks.length &&
      selectedTasks.reduce((prev: any, cur: any) => {
        if (!prev) return false;

        if (prev.state !== cur.state) return false;
        if (prev.workflowType !== cur.workflowType) return false;
        if (prev.locale.toLowerCase() !== cur.locale.toLowerCase()) return false;

        return cur;
      })
    );
  };

  const handleOnTaskUpdate = useCallback(
    (updateAttribute: string, updateValue: string | boolean) => {
      setLastClickedAction(updateAttribute);
      const selectedTaskMap: { [key: string]: string[] } = {};

      selectedTasks.forEach((task: TaskListItem) => {
        if (selectedTaskMap.hasOwnProperty(task.namespace)) {
          selectedTaskMap[task.namespace].push(task.taskGuuid);
        } else {
          selectedTaskMap[task.namespace] = [task.taskGuuid];
        }
      });

      for (const [namespace, selectedTaskIds] of Object.entries(selectedTaskMap)) {
        dispatch(
          updateBulkTaskRequest({
            updateItems: createBulkUpdatePayload(
              selectedTaskIds,
              namespace,
              updateAttribute,
              updateValue
            ),
            updateAttribute,
            updateValue
          })
        );
      }
    },
    [dispatch, selectedTasks]
  );

  /* 
    FIXME: Not ideal workaround - Tidbits Modal/Tidbits Dropdown has issues
    with interactions, and useRefs here not ideal with our functional components.
    Same applies to handleOnImportMusic and handleOnImportVideo handlers.
  */
  const handleOnImportMusic = useCallback((e: SyntheticEvent) => {
    const modalButton = document.getElementById('hiddenModalButton');
    if (modalButton) {
      modalButton.setAttribute('data-workflowType', 'MUSIC');
      modalButton.click();
    }
  }, []);

  const handleOnImportVideo = useCallback((e: SyntheticEvent) => {
    const modalButton = document.getElementById('hiddenModalButton');
    if (modalButton) {
      modalButton.setAttribute('data-workflowType', 'VIDEO');
      modalButton.click();
    }
  }, []);

  const handleOnImportApp = useCallback((e: SyntheticEvent) => {
    const modalButton = document.getElementById('hiddenModalButton');
    if (modalButton) {
      modalButton.setAttribute('data-workflowType', 'APP');
      modalButton.click();
    }
  }, []);

  const handleOnImportCW = useCallback((e: SyntheticEvent) => {
    const modalButton = document.getElementById('hiddenModalButton');
    if (modalButton) {
      modalButton.setAttribute('data-workflowType', 'COPY_WORKFLOW');
      modalButton.click();
    }
  }, []);

  const handleOnExportReport = (e: SyntheticEvent) => {
    if (!selectedTaskIds.length) return;
    dispatch(
      showModal(
        {
          open: true,
          size: ModalSize.SMALL,
          title: '',
          leftButtonLabel: '',
          rightButtonLabel: '',
          submitAction: exportReportSubmitAction,
          data: null,
          exportSelectedTasks: false
        },
        MODAL_CONSTANTS.exportValid
      )
    );
  };

  const handleBulkRetry = (e: SyntheticEvent) => {
    handleOnTaskUpdate('retry', true);
  };

  const handleOnExport = (e: SyntheticEvent) => {
    dispatch(
      showModal(
        {
          open: true,
          size: ModalSize.SMALL,
          title: '',
          leftButtonLabel: '',
          rightButtonLabel: '',
          submitAction: exportWorklist,
          data: null,
          exportSelectedTasks: true
        },
        MODAL_CONSTANTS.exportValid
      )
    );
  };

  const handleOnShareUrl = (e: SyntheticEvent) => {
    const text = `${window.location.host}${pathname}#${JSON.stringify(activeFilters)}`;
    navigator.clipboard.writeText(text);
  };

  const handleOnShareFilterPresets = (e: SyntheticEvent) => {
    showShareFiltersModal(
      <ShareFiltersModal onClose={hideShareFiltersModal} filterConfig={NewTableFilterConfig} />
    );
  };

  const handleOnAddFilterPresets = (e: SyntheticEvent) => {
    showImportFilterModal(
      <ImportFiltersModal onClose={hideImportFilterModal} filterConfig={NewTableFilterConfig} />
    );
  };
  const handleExtensionRequest = (e: SyntheticEvent) => {
    let minDate = new Date();
    minDate.setDate(minDate.getDate() + 2);
    showRequestExtensionModal(
      <DueDateExtensionModal
        onClose={hideRequestExtensionModal}
        date={{
          label: '',
          key: '',
          taskDateObj: {
            value: minDate.toISOString(),
            min: minDate.toISOString(),
            max: '',
            isCompleted: false,
            isOverdue: false,
            isEditable: true
          }
        }}
        selectedTasks={selectedTasks.map(({ title, workflowGuuid }) => ({
          title,
          workflowGuuid: workflowGuuid || ''
        }))}
        zIndex={1}
      />
    );
  };

  const isArchiveActionDisabled = useCallback(() => {
    if (
      !selectedTasks.length ||
      bothArchivedUnarchivedTasksInSelection ||
      disableCommandFlagAction(selectedTasks, isUnarchived ? 'archive' : 'unarchive')
    )
      return true;

    const userPermissionsForArchiving = userPermissions.filter((perm) =>
      ALL_ARCHIVE_PERMISSIONS.includes(perm)
    );

    const archivableWorkflowTypes = userPermissionsForArchiving.map((vertical) => {
      switch (vertical) {
        case 'CW_CAN_DO_WORKFLOW_ARCHIVE':
          return 'COPY_WORKFLOW';
        case 'ACW_CAN_DO_WORKFLOW_ARCHIVE':
          return 'APP_CANVAS';
        case 'MCW_CAN_DO_WORKFLOW_ARCHIVE':
          return 'MUSIC_CONTENT';
        case 'VCW_CAN_DO_WORKFLOW_ARCHIVE':
          return 'VIDEO_CONTENT';
        default:
          return '';
      }
    });

    const canArchive = selectedTasks.every((task: any) =>
      archivableWorkflowTypes.includes(task.workflowType)
    );

    return !canArchive;
  }, [bothArchivedUnarchivedTasksInSelection, isUnarchived, selectedTasks, userPermissions]);

  const isMTARActionDisabled = useMemo(() => {
    return disableCommandFlagAction(selectedTasks, isMarkedAsRead ? 'unread' : 'read');
  }, [selectedTasks, isMarkedAsRead]);

  const MTARIsVisible = useMemo(() => {
    return userHasPermissionToMTAR(userPermissions);
  }, [userPermissions]);

  const actions = [
    {
      label: isUnarchived ? 'Archive' : 'Unarchive',
      isDisabled: isArchiveActionDisabled(),
      isVisible: isAllowedByPermissions(ALL_ARCHIVE_PERMISSIONS, userPermissions),
      handleOnClick: () => handleOnTaskUpdate('isActive', !isUnarchived),
      key: 'archive',
      isLoading: lastClickedAction === 'isActive' && isBulkUpdatingTasks
    },
    {
      label: isMarkedAsRead ? 'Mark as Unread' : 'Mark As Read',
      isDisabled: !selectedTasks.length || isMarkedAsReadDisabled || isMTARActionDisabled,
      isVisible: MTARIsVisible,
      handleOnClick: () => handleOnTaskUpdate('readFlag', !isMarkedAsRead),
      key: 'mtar',
      isLoading: lastClickedAction === 'readFlag' && isBulkUpdatingTasks
    },
    {
      label: selectedTasks.length === 1 ? 'Reassign Task' : 'Reassign Task(s)',
      isVisible: true,
      isDisabled: !selectedTasks.length || isReassignDisabled,
      handleOnClick: showReassignModal,
      key: 'reassign'
    },
    {
      label: 'Retry',
      isDisabled: selectedTasks.length === 0 || isRetryDisabled,
      isVisible: isAllowedByPermissions(
        [
          'ACW_CAN_DO_WORKFLOW_SUPER_ADMIN',
          'ACW_CAN_DO_WORKFLOW_LOC_PRODUCER',
          'CW_CAN_DO_WORKFLOW_SUPER_ADMIN',
          'CW_CAN_DO_WORKFLOW_LOC_PRODUCER',
          'MCW_CAN_DO_WORKFLOW_SUPER_ADMIN',
          'MCW_CAN_DO_WORKFLOW_LOC_PRODUCER',
          'VCW_CAN_DO_WORKFLOW_SUPER_ADMIN',
          'VCW_CAN_DO_WORKFLOW_LOC_PRODUCER'
        ],
        userPermissions
      ),
      handleOnClick: handleBulkRetry,
      key: 'retry',
      isLoading: lastClickedAction === 'retry' && isBulkUpdatingTasks
    },
    {
      label: 'Import',
      collapsible: true,
      isVisible: true,
      actions: [
        {
          label: 'Import App Workflow',
          isDisabled: false,
          isVisible: userHasMultiplePermissions(
            ['CAN_DO_WORKFLOW_WORKLIST_IMPORT', 'ACW_CAN_ACCESS_NAMESPACE'],
            userPermissions
          ),
          handleOnClick: handleOnImportApp,
          key: 'import_acw'
        },
        {
          label: 'Import Copy Workflow',
          isDisabled: false,
          isVisible: userHasMultiplePermissions(
            ['CAN_DO_WORKFLOW_WORKLIST_IMPORT', 'CW_CAN_ACCESS_NAMESPACE'],
            userPermissions
          ),
          handleOnClick: handleOnImportCW,
          key: 'import_cw'
        },
        {
          label: 'Import Music Workflow',
          isDisabled: false,
          isVisible: userHasMultiplePermissions(
            ['CAN_DO_WORKFLOW_WORKLIST_IMPORT', 'MCW_CAN_ACCESS_NAMESPACE'],
            userPermissions
          ),
          handleOnClick: handleOnImportMusic,
          key: 'import_mcw'
        },
        {
          label: 'Import Video Workflow',
          isDisabled: false,
          isVisible: userHasMultiplePermissions(
            ['CAN_DO_WORKFLOW_WORKLIST_IMPORT', 'VCW_CAN_ACCESS_NAMESPACE'],
            userPermissions
          ),
          handleOnClick: handleOnImportVideo,
          key: 'import_vcw'
        }
      ]
    },
    {
      label: 'Export',
      collapsible: true,
      isVisible: true,
      actions: [
        {
          label: 'Export Report',
          isDisabled: !selectedTasks.length || isExportDisabled(),
          handleOnClick: handleOnExportReport,
          isVisible: true,
          key: 'report'
        },
        {
          label:
            selectedTaskIds.length === 1
              ? `Export Selected Task`
              : `Export ${selectedTaskIds.length} Selected Task(s)`,
          isDisabled: !selectedTasks.length || isExportDisabled(),
          disabldInfo: 'Only one workflow type may be selected for exporting functions',
          showDisabledInfo: selectedTaskIds.length > 0 && isExportDisabled(),
          showInfo: selectedTaskIds.length > 0 && !isExportDisabled(),
          info: 'This will change the status of the selected task(s) to Exported',
          handleOnClick: handleOnExport,
          isVisible: true,
          key: 'export'
        }
      ]
    },
    {
      label: 'Share Filters',
      collapsible: true,
      isVisible: devEnabledOnly,
      actions: [
        {
          label: 'Share Filter as URL',
          key: 'share-filter-url',
          handleOnClick: handleOnShareUrl
        },
        {
          label: 'Share Filter Presets',
          key: 'share-filter-presets',
          handleOnClick: handleOnShareFilterPresets
        },
        {
          label: 'Add Filter Presets',
          key: 'share-filters-add-presets',
          handleOnClick: handleOnAddFilterPresets
        }
      ]
    },
    {
      label: 'Request Extensions',
      isVisible: false, //TODO: Restore to true once we fix issues with due date extension request
      isDisabled: !selectedTasks.length,
      key: 'ReqExt',
      handleOnClick: handleExtensionRequest
    }
  ];

  return actions;
};
