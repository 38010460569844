"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.putPreferences = exports.getPreferences = void 0;
const axiosInstance_1 = require("./axiosInstance");
const USER_PREFERENCES_PATH = 'api/userpreferences';
const getQueryParamKey = (keys) => {
    return keys.map((key) => `key=${key}`).join('&');
};
const getPreferences = (contextId, keys) => {
    const keyString = getQueryParamKey(keys);
    const url = `/${USER_PREFERENCES_PATH}/${contextId}?${keyString}`;
    return axiosInstance_1.axiosInstance.get(url).then((res) => res.data);
};
exports.getPreferences = getPreferences;
const putPreferences = (contextId, key, payload) => {
    const url = `/${USER_PREFERENCES_PATH}/${contextId}`;
    const putPayload = [
        {
            prefKey: key,
            payload
        }
    ];
    return axiosInstance_1.axiosInstance.put(url, putPayload).then((res) => res.data);
};
exports.putPreferences = putPreferences;
exports.default = {
    getPreferences: exports.getPreferences,
    putPreferences: exports.putPreferences
};
