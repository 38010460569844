"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.queryClientConfig = void 0;
const react_query_1 = require("react-query");
const notifications_1 = require("@ui-components/notifications");
exports.queryClientConfig = {
    queryCache: new react_query_1.QueryCache({
        onError: (error, query) => {
            if (query.state.data !== undefined) {
                (0, notifications_1.showToastMessage)(error, { type: 'error' });
            }
        }
    }),
    defaultOptions: {
        queries: {
            staleTime: Infinity,
            cacheTime: Infinity
        }
    }
};
