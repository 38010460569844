"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCopyCheckScore = exports.useCopyCheckSummary = exports.useCharacterCounts = exports.useVendor = exports.useSummary = exports.useBatchSummary = exports.useGetComments = exports.useLoggedInUser = exports.useUser = exports.useGetQueryData = void 0;
const text_utils_1 = require("@helper-hooks/text-utils");
const types_provider_1 = require("@providers/types-provider");
const useGetQueryData = (useQueryClient, queryKey, queryFilters) => {
    const queryClient = useQueryClient();
    const data = queryClient.getQueryData([queryKey, queryFilters], {
        exact: false
    });
    return data;
};
exports.useGetQueryData = useGetQueryData;
const useUser = (useQueryClient) => {
    const data = (0, exports.useGetQueryData)(useQueryClient, types_provider_1.SharedQueryKeys.VENDOR);
    if (!data)
        throw new Error('No User Data!');
    return data;
};
exports.useUser = useUser;
const useLoggedInUser = (useQueryClient) => {
    const data = (0, exports.useGetQueryData)(useQueryClient, types_provider_1.SharedQueryKeys.USER);
    if (!data)
        throw new Error('No LoggedIn User Data!');
    return data;
};
exports.useLoggedInUser = useLoggedInUser;
const useGetComments = (useQueryClient) => {
    const data = (0, exports.useGetQueryData)(useQueryClient, types_provider_1.SharedQueryKeys.COMMENTS);
    if (!data)
        throw new Error('No Comments Data!');
    return data;
};
exports.useGetComments = useGetComments;
const useBatchSummary = (useQueryClient) => {
    const data = (0, exports.useGetQueryData)(useQueryClient, types_provider_1.SharedQueryKeys.COLLABORATION_BATCH_SUMMARY);
    if (!data)
        return {};
    return data;
};
exports.useBatchSummary = useBatchSummary;
const useSummary = (useQueryClient, threadId) => {
    const data = (0, exports.useBatchSummary)(useQueryClient);
    const summary = data[threadId];
    return summary;
};
exports.useSummary = useSummary;
const useVendor = (useQueryClient) => {
    return (0, exports.useUser)(useQueryClient).vendor;
};
exports.useVendor = useVendor;
const useCharacterCounts = (useQueryClient) => {
    const key = types_provider_1.SharedQueryKeys.CHARACTER_COUNTS;
    const data = (0, exports.useGetQueryData)(useQueryClient, key);
    const mappedCharacters = data && (0, text_utils_1.convertUnicodeListToMap)(data);
    return mappedCharacters;
};
exports.useCharacterCounts = useCharacterCounts;
const useCopyCheckSummary = (useQueryClient) => {
    const data = (0, exports.useGetQueryData)(useQueryClient, types_provider_1.SharedQueryKeys.COPY_CHECK);
    if (!data)
        return {};
    return data;
};
exports.useCopyCheckSummary = useCopyCheckSummary;
const useCopyCheckScore = (useQueryClient) => {
    const data = (0, exports.useCopyCheckSummary)(useQueryClient);
    return data.score;
};
exports.useCopyCheckScore = useCopyCheckScore;
