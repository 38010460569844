import React from 'react';
import { Column } from '@ui-components/table';
import { UserToReassign } from '../../common/types/UserToReassign';
import { QualifiedUser } from '@providers/types-provider';
import { UserDisplayName } from './components/UserDisplayName';

export const reassignConfig: Column<UserToReassign>[] = [
  {
    title: 'Full Name',
    key: 'fullName',
    dataIndex: 'name',
    sortable: true,
    defaultSort: 'asc',
    customRender: (rowData) => rowData?.name || ''
  },
  {
    title: 'Email',
    key: 'email',
    dataIndex: 'email',
    sortable: true,
    defaultSort: 'asc',
    customRender: (rowData) => rowData?.email || ''
  },
  {
    title: 'Vendor',
    key: 'vendor',
    dataIndex: 'vendor',
    sortable: true,
    defaultSort: 'asc',
    customRender: (rowData) => rowData?.vendor || ''
  }
];

export const reassignConfigV2: Column<QualifiedUser>[] = [
  {
    title: 'Display Name',
    key: 'displayName',
    dataIndex: 'displayName',
    sortable: true,
    defaultSort: 'asc',
    customRender: (rowData) => {
      return rowData?.displayName ? <UserDisplayName userFullName={rowData.displayName} /> : '';
    }
  },
  {
    title: 'Email',
    key: 'email',
    dataIndex: 'email',
    sortable: true,
    defaultSort: 'asc',
    customRender: (rowData) => rowData?.email || ''
  },
  {
    title: 'Organization',
    key: 'organization',
    dataIndex: 'organization',
    sortable: true,
    defaultSort: 'asc',
    customRender: (rowData) => rowData?.organization || ''
  }
];
