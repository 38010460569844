"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCopyChecks = void 0;
const useGetSharedQueryData_1 = require("./useGetSharedQueryData");
const useCopyChecks = (useQueryClient, inputState) => {
    const { text } = inputState;
    const copyCheckSummary = (0, useGetSharedQueryData_1.useCopyCheckSummary)(useQueryClient);
    const confidenceScore = copyCheckSummary.score;
    return {
        copyCheckSummary,
        confidenceScore,
        text
    };
};
exports.useCopyChecks = useCopyChecks;
