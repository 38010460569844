import React from 'react';
import { Box } from '@tidbits/react-tidbits';
import UserAvatar from '@ui-components/user-avatar';
import { getInitials } from '@helper-hooks/general-utils';

type UserDisplayNameProps = {
  userFullName: string;
};

export const UserDisplayName = ({ userFullName }: UserDisplayNameProps) => {
  return (
    <Box display="flex" alignItems="center">
      <UserAvatar displayName={getInitials(userFullName)} size="sm" />
      {userFullName}
    </Box>
  );
};
