"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const axiosInstance_1 = require("./axiosInstance");
const translationMemory = {
    search: (searchQuery) => {
        return axiosInstance_1.axiosInstance
            .post(`/api/translation-memory/v1/search`, searchQuery)
            .then((res) => res.data);
    },
    filterTerms: (limit) => {
        return axiosInstance_1.axiosInstance
            .get('/api/translation-memory/v1/search/translationMemory/filters', {
            params: {
                limit: limit || 20
            }
        })
            .then((res) => res.data);
    },
    searchWithFilters: (searchQuery) => {
        return axiosInstance_1.axiosInstance
            .post(`/api/translation-memory/v1/search`, searchQuery)
            .then((res) => res.data);
    },
    getGlossaryTerms: ({ locale, translationLocale, text, termTypes, lineOfBusiness }) => {
        return axiosInstance_1.axiosInstance
            .post('/api/translation-memory/v1/glossaryMatches', Object.assign({ text,
            locale,
            translationLocale,
            termTypes }, (lineOfBusiness ? { lineOfBusiness } : {})))
            .then((result) => result.data);
    },
    getTMThreshold: () => {
        return axiosInstance_1.axiosInstance
            .get('/api/api2/v1/translation-memory/thresholds')
            .then((result) => result.data);
    }
};
exports.default = translationMemory;
