import { FeatureFlag } from '../../common/types/FeatureFlag';

export enum FeatureFlagNames {
  MENTIONS = 'MENTIONS',
  HIGHLIGHTS = 'HIGHLIGHTS',
  ADD_NEW_USER = 'ADD_NEW_USER',
  NOTIFICATIONS = 'NOTIFICATIONS',
  TASK_HISTORY = 'TASK_HISTORY',
  PREVIEW = 'PREVIEW'
}

const makeInitialStatePerEnv = () => {
  const featureFlagString = JSON.stringify(process.env.REACT_APP_FEATURE_FLAG);
  const featureFlagsEnv = JSON.parse(featureFlagString || '{}');
  return featureFlagsEnv;
};

const initialState: FeatureFlag[] = makeInitialStatePerEnv();

const featureFlagReducer = (state: FeatureFlag[] = initialState) => state;

export default featureFlagReducer;
