import React, { FC } from 'react';
import { connect } from 'react-redux';

import { hideModal } from '../../store/modal/modalActions';
import { getSelectedTasksIds, getTaskNumber } from '../../store/insertTask/insertTaskSelector';
import { getWorklistSelectedIds, getWorklistNumber } from '../../store/worklist/worklistSelector';
import { ReduxState } from '../../common/types';
import InfoModal from '../modals/InfoModal/InfoModal';

interface Props {
  closeModal: () => void;
  submitAction: (ids: Object) => void;
  selectedTasks: number[];
  selectedWorklistTasks: string[];
  taskNumber: number;
  worklistNumber: number;
  exportSelectedTasks: boolean;
}

interface StateProps {
  selectedTasks: number[];
  selectedWorklistTasks: string[];
  taskNumber: number;
  worklistNumber: number;
}

interface DispatchProps {
  closeModal: () => void;
}

type ComponentProps = Props & StateProps & DispatchProps;

export const ExportValidModalContent: FC<ComponentProps> = (props) => {
  const { closeModal, submitAction, selectedWorklistTasks, worklistNumber, exportSelectedTasks } =
    props;

  const selected = selectedWorklistTasks;
  const number = worklistNumber;

  const numberOfTasksToExport = selected.length === number ? 'all' : selected.length;

  const title =
    numberOfTasksToExport === 1
      ? 'Are you sure you want to export 1 task?'
      : `Are you sure you want to export ${numberOfTasksToExport} tasks?`;

  const subtitle =
    numberOfTasksToExport === 1
      ? 'Exported task will be generated and downloaded in .xlsx format'
      : 'Exported tasks will be generated and downloaded in .xlsx format';

  return (
    <InfoModal
      title={title}
      closeModal={closeModal}
      onSuccess={() => {
        if (selected.length === number) {
          submitAction({ ids: [] });
        } else {
          submitAction({ ids: selected });
        }
        closeModal();
      }}
      successLabel={'Export'}
    >
      {exportSelectedTasks
        ? 'Exported tasks will be updated to Exported Status and downloaded in .xlsx format.'
        : subtitle}
    </InfoModal>
  );
};

const mapStateToProps = (state: ReduxState): StateProps => ({
  selectedTasks: getSelectedTasksIds(state),
  selectedWorklistTasks: getWorklistSelectedIds(state),
  taskNumber: getTaskNumber(state),
  worklistNumber: getWorklistNumber(state)
});

const mapDispatchToProps: DispatchProps = {
  closeModal: hideModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportValidModalContent);
