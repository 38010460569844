import { QualifiedUser } from '@providers/types-provider';

export const mappedTaskState = (key: string) => {
  // key should be worklist item -> state
  switch (key) {
    case 'submit_copy':
    case 'write':
      return 'writer';
    case 'external_review':
    case 'edit':
      return 'editor';
    case 'onsite_review':
    case 'adapt_internal_review':
    case 'review_copy':
    case 'internal_review':
    case 'review':
      return 'reviewer';
    case 'geo_lead_review':
      return 'geo_lead';
    case 'vendor_review':
    case 'adapt_review':
    case 'adaptation_edit':
    case 'loc_producer_review':
      return 'loc_producer';
    default:
      return '';
  }
};

export const emptyUser = {
  identifier: '',
  displayName: '',
  firstName: '',
  lastName: '',
  organization: '',
  email: '',
  uniqueUserSourceId: '',
  userSource: '',
  availableRealmIdentifiers: []
} as QualifiedUser;
