import { Skill, WorkspaceUserDetails } from '@providers/types-provider';
import React from 'react';
import { Text, Box } from '@tidbits/react-tidbits';
import AccordionList from '@ui-components/accordion-list';
import styled from 'styled-components';

const AccordionTitleContainer = styled.div`
  padding: 8px;
`;

const WorkspaceContainer = styled.div`
  background-color: white;
  border-top: 1px #d6d6d6 solid;
  border-left: 1px #d6d6d6 solid;
  border-right: 1px #d6d6d6 solid;
  margin-bottom: 8px;
`;

const WorkspaceBody = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const WorkspaceSection = styled.div`
  padding: 6px;
  flex-grow: 1;
`;

const SkillSubsection = styled.div`
  margin-bottom: 6px;
`;

const AccordionTitle = ({ title }: { title: string }) => {
  return (
    <AccordionTitleContainer>
      <Text color="labelPlaceholder" mb="3px">
        Workspace
      </Text>
      <Text textStyle="h5Emph" color="title">
        {title}
      </Text>
    </AccordionTitleContainer>
  );
};

interface Props {
  workspace: WorkspaceUserDetails;
}

export const LoggedInUserProfileWorkspace = ({ workspace }: Props) => {
  return (
    <WorkspaceContainer key={workspace.workspaceName}>
      <AccordionList.Accordion
        title={<AccordionTitle title={workspace.workspaceName} key={workspace.workspaceName} />}
        name={workspace.workspaceName}
      >
        <WorkspaceBody>
          <WorkspaceSection>
            <Text textStyle="bodyRegular" color="labelPlaceholder" mb="8px">
              Skills
            </Text>
            {workspace.skills.length > 0
              ? workspace.skills.map((skill: Skill, i) => {
                  const locales =
                    skill.locales && skill.locales.length > 0
                      ? skill.locales.map((locale) => locale.displayName).join(', ')
                      : null;
                  const departments =
                    skill.departments && skill.departments.length > 0
                      ? skill.departments.map((department) => department.displayName).join(', ')
                      : null;

                  return (
                    <Box>
                      <Text textStyle="bodyEmph" mb="4px">
                        {skill.skillName}
                      </Text>

                      {locales ? (
                        <SkillSubsection>
                          <Text textStyle="bodySmallEmph" mb="2px">
                            Locales
                          </Text>
                          <Text textStyle="bodySmall">{locales}</Text>
                        </SkillSubsection>
                      ) : null}
                      {departments ? (
                        <SkillSubsection>
                          <Text textStyle="bodySmallEmph" mb="2px">
                            Departments
                          </Text>
                          <Text textStyle="bodySmall">{departments}</Text>
                        </SkillSubsection>
                      ) : null}
                    </Box>
                  );
                })
              : null}
          </WorkspaceSection>
          <WorkspaceSection>
            <Text textStyle="bodyRegular" color="labelPlaceholder" mb="8px">
              Vendor Company
            </Text>
            <Text mb="8px" textStyle="bodyEmph">
              {workspace.vendor}
            </Text>
          </WorkspaceSection>
        </WorkspaceBody>
      </AccordionList.Accordion>
    </WorkspaceContainer>
  );
};
